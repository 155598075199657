.tabs {
  display: flex;
  flex-direction: column;
  gap: var(--space-between-m);
}

.tab-full-width {
  a {
    max-width: unset;
  }
}

.tab__icon--colour-virgin-red {
  // the red needed (for Volt) is not available in the theme.
  color: #e10a0a;
}

.tab__icon--colour-o2-blue {
  color: var(--color-primary-bg);
}
