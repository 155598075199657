@use '@virginmediao2/momentum-css/react/components/section';

// Background Color Variants
@each $variant,
  $color
    in (
      'grey': #ddd,
      'blue': #0050ff,
      'darkblue': #00008c,
      'lightblue': #82dcfa,
      'orange': #ffa55a,
      'violet': #fa96ff,
      'yellow': #fad200,
      'lightgreen': #00dcd2
    )
{
  .rpi--variant-#{$variant} {
    background-color: $color;
  }

  // Specific color overrides for text within variant
  @if $variant == 'blue' or $variant == 'darkblue' {
    .rpi--variant-#{$variant} p {
      color: var(--mo-color-0) !important;
    }
  }
}

.rpi--fontsize-mobile-100 p {
  font-size: 11px;
}

.rpi--sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

@media (width < 48em) {
  .rpi--fontsize-mobile-100 p {
    font-size: var(--font-size-100) !important;
  }
  .rpi--fontsize-mobile-200 p{
    font-size: var(--font-size-200) !important;
  }
  .rpi--fontsize-mobile-300 p {
    font-size: var(--font-size-300) !important;
  }
  .rpi--fontsize-mobile-400 {
    font-size: var(--font-size-400) !important;
  }
  .rpi--fontsize-mobile-500 p{
    font-size: var(--font-size-500) !important;
  }
  .rpi--fontsize-mobile-600 {
    font-size: var(--font-size-600) !important;
  }
  .rpi--fontsize-mobile-700 {
    font-size: var(--font-size-700) !important;
  }
  .rpi--fontsize-mobile-800 {
    font-size: var(--font-size-800) !important;
  }
  .rpi--fontsize-mobile-900 {
    font-size: var(--font-size-900) !important;
  }
}

@media (48em <= width < 64em) {
  /* Tablet styles */
  .rpi--fontsize-tablet-100 p{
    font-size: var(--font-size-100) !important;
  }
  .rpi--fontsize-tablet-200 p{
    font-size: var(--font-size-200) !important;
  }
  .rpi--fontsize-tablet-300 p{
    font-size: var(--font-size-300) !important;
  }
  .rpi--fontsize-tablet-400 p{
    font-size: var(--font-size-400) !important;
  }
  .rpi--fontsize-tablet-500 p{
    font-size: var(--font-size-500) !important;
  }
  .rpi--fontsize-tablet-600 p{
    font-size: var(--font-size-600) !important;
  }
  .rpi--fontsize-tablet-700 p{
    font-size: var(--font-size-700) !important;
  }
  .rpi--fontsize-tablet-800 p{
    font-size: var(--font-size-800) !important;
  }
  .rpi--fontsize-tablet-900 p{
    font-size: var(--font-size-900) !important;
  }
}

@media (width >= 64em) {
  .rpi--fontsize-desktop-100 p{
    font-size: var(--font-size-100) !important;
  }
  .rpi--fontsize-desktop-200 p{
    font-size: var(--font-size-200) !important;
  }
  .rpi--fontsize-desktop-300 p{
    font-size: var(--font-size-300) !important;
  }
  .rpi--fontsize-desktop-400 p{
    font-size: var(--font-size-400) !important;
  }
  .rpi--fontsize-desktop-500 p{
    font-size: var(--font-size-500) !important;
  }
  .rpi--fontsize-desktop-600 p{
    font-size: var(--font-size-600) !important;
  }
  .rpi--fontsize-desktop-700 p{
    font-size: var(--font-size-700) !important;
  }
  .rpi--fontsize-desktop-800 p{
    font-size: var(--font-size-800) !important;
  }
  .rpi--fontsize-desktop-900 p{
    font-size: var(--font-size-900) !important;
  }
  .rpi--layout-comfy .rpi__content {
    grid-column: 1 / span 10;
  }
}
