/* stylelint-disable selector-max-compound-selectors */
@use '@virginmediao2/momentum-css/react/components/o2-footer';

.o2-footer {
  margin-top: 0;
  background: var(--color-footer);
}

.o2-footer__navigation {
  gap: 16px;
}

/**
 * TODO: give anchor links the option to have bordered icons
 */

.o2-footer__navigation-links-col-1 a > span:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  padding: 6px 6px 6px 8px;
  margin-right: 16px;
  border: 2px solid #fff;
  border-radius: 99vw;
}

.o2-footer__navigation-links-col-1 a > span:last-child > span {
  --icon-size: 1.3rem;
}

/**
   * TODO: Figure out a better way to handle this
   */

.o2-footer__navigation-links-col-1 ul {
  --item-spacing: 16px;
}

.o2-footer__navigation-links-col-1 > div:first-child {
  padding-top: 0;
  border-top: 0;
}

.o2-footer__bottom-row ul {
  --item-spacing: 0;
}

.o2-footer__bottom-row a {
  padding-inline: 2px;
}

.o2-footer__background-image {
  top: 50%;
  width: 194px;
  height: 366px;
  background: url('../../../../../public/images/footer-bubbles.svg') no-repeat;
}

@media (width >= 48em) {
  .o2-footer__background-image {
    top: unset;
    bottom: 0;
    width: 268px;
    height: 407px;
  }
}

.o2-footer__bottom-telefonica-legals p {
  line-height: 20px;
}
