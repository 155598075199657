.shout-out-banner__container {
  position: relative;
  display: grid;
  grid-template-areas: 'item';
  width: 100%;
  text-decoration: none;
  justify-items: center;
}

.shout-out-banner__image {
  position: relative;
  grid-area: item;
  overflow: hidden;
  max-width: var(--mo-container-max-width);
  width: 100%;
  min-height: 160px;
  max-height: 600px;
  z-index: var(--zindex-shout-out-base);
  border-radius: var(--border-radius-m);

  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
  }
}

.shout-out-banner {
  display: flex;
  grid-area: item;
  justify-content: center;
  max-width: var(--mo-container-max-width);
  width: 100%;
  min-height: 150px;
  background-color: var(--mo-color-100);
  color: var(--mo-color-0);
  border-radius: var(--border-radius-m);
}

.shout-out-banner__inner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  width: 100%;
  max-width: var(--mo-container-max-width);
  height: 100%;
  padding: 1.5rem;
  padding-top: 3rem;
  z-index: var(--zindex-shout-out-base);
}

.shout-out-banner__content {
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 0.5rem;
  }
}

.shout-out-banner__image--has-padding-top {
  padding-top: 100px;
}

.shout-out-banner__link-container {
  display: inline;
  margin-top: 1em;
}

.shout-out-banner--light {
  background-color: var(--mo-color-0);
  color: var(--mo-color-100);
}

.shout-out-banner--gradient {
  background-image: linear-gradient(#23246b, #1375ba);
}

@media (width >= 48em) {
  .shout-out-banner__inner {
    grid-template-columns: 1fr 1fr;
  }
  .shout-out-banner__content {
    justify-content: space-between;
  }
  .shout-out-banner__image--has-padding-top {
    padding-top: 0;
  }
}
