/* stylelint-disable selector-no-qualifying-type */
@use '@virginmediao2/momentum-css/react/components/tile';

.tile {
  gap: var(--padding-m);
  width: 100%;
  padding: var(--padding-s);
  color: var(--color-surface-fg);
}

.tile:has(> .tile__icon) {
  padding: var(--padding-xl);
}

.tile--hide-title .tile__text {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.tile__icon,
.tile__icon img {
  max-width: 32px;
  max-height: 32px;
}

a.tile:not([href]),
a.tile[href=''] {
  color: var(--color-destructive-fg);
  cursor: not-allowed;
  background: var(--color-destructive-bg);
}
