.mega-menu-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--mo-brand-primary);
}

@media (width < 48em) {
  .mega-menu-topbar__list-item--hidden {
    display: none;
  }

  .mega-menu-topbar {
    justify-content: flex-end;
  }
}
