.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-height: 100dvh;
}

.page__body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-width: 288px;
}
